import React from "react";
import BackgroundImage from "gatsby-background-image/index";
import {graphql, Link} from "gatsby";
import {Col, Container, Row} from "react-bootstrap";

import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import HeaderCol from '../components/headerCol/headerCol';
import Swirl from '../images/backgrounds/bg-swirl.svg';

const GetStartedPage = ({ data }) => {
  // Map ACF group content to variable
  const content = data.wpPage.pageGetStarted;

  return (
    <Layout>
      <Seo
        title={data.wpPage.seo.metaTitle}
        description={data.wpPage.seo.metaDesc}
        image={data.wpPage.seo.opengraphImage?.localFile.publicURL}
        ogTitle={data.wpPage.seo.opengraphTitle}
        ogDescription={data.wpPage.seo.opengraphDescription}
        ogImage={data.wpPage.seo.opengraphImage?.localFile.publicURL}
        twitterTitle={data.wpPage.seo.twitterTitle}
        twitterDescription={data.wpPage.seo.twitterDescription}
        twitterImage={data.wpPage.seo.twitterImage?.localFile.publicURL}
      />

      <BackgroundImage
        Tag="header"
        fluid={content.getStartedHeader.background.localFile.childImageSharp.fluid}
        fadeIn={false}
        style={{ opacity: `1 !important` }}
      >
        <Container>
          <Row>
            <HeaderCol>
              <h1>{content.getStartedHeader.headline}</h1>
              <p>{content.getStartedHeader.subheadline}</p>
            </HeaderCol>
          </Row>
        </Container>

        {/* eslint-disable jsx-a11y/alt-text */}
        <img
          src={Swirl}
          style={{opacity: 1, transform: 'translateY(1px)', height: 'auto', width: '100vw'}}
        />
        {/* eslint-enable jsx-a11y/alt-text */}
      </BackgroundImage>

      <section>
        <Container className='py-4 py-md-6'>
          <Row className="justify-content-center text-center">
            <HeaderCol>
              <h2>{content.getStartedForm.headline}</h2>
              <p>{content.getStartedForm.subheadline}</p>
            </HeaderCol>
          </Row>

          <div className='mt-3'>
            <typeform-widget url="https://form.typeform.com/to/SRWWJxGq"/>
          </div>
        </Container>

        <div className="banner-secondary">
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={8}>
                <h4>{content.getStartedBrowseBanner.headline}</h4>
              </Col>

              <Col xs={12} md={4} className='text-md-center text-left'>
                <Link to="/browse/" className='btn btn-gradient d-inline-flex'>
                  {content.getStartedBrowseBanner.button}
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 72 }) {
        seo {
            metaDesc
            metaTitle
            twitterDescription
            twitterTitle
            twitterImage {
                localFile {
                    publicURL
                }
            }
            opengraphTitle
            opengraphDescription
            opengraphImage {
                localFile {
                    publicURL
                }
            }
        }
      pageGetStarted {
        getStartedHeader {
          headline
          subheadline
          background {
            localFile {
              ...imageFluidExtraLarge
            }
          }
        }
        getStartedForm {
          headline
          subheadline
        }
        getStartedBrowseBanner {
          headline
          button
        }
      }
    }
  }
`;

export default GetStartedPage;
